<template>
  <!-- begin register -->
  <div class="page-content col-lg-12 col-xl-10">
    <!-- <div class="right-content"> -->
    <!-- begin card -->
    <div class="card bg-grey-lighter m-b-20 col-md-12">
      <div class="card-body col-lg-4">
        <blockquote class="blockquote">
          <h2 class="page-header text-left text-black-lighter">企业信息</h2>
        </blockquote>
      </div>
    </div>
    <!-- end card -->
    <div class="register-content col-md-12">
      <form v-on:submit="checkForm" method="POST">
        <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
          <!-- panel-body begin -->
          <div class="panel-body">
            <button type="submit" class="footer">保存</button>

            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >公司名称：</label
              >
              <div class="col-lg-4 col-md-9 m-b-10">
                <input
                  class="form-control"
                  data-parsley-range="[5,10]"
                  v-model="BasicInfomation.name"
                  required
                />
              </div>
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >社会信用代码：</label
              >
              <div class="col-lg-4 col-md-9 m-b-10">
                <div class="input-group">
                  <input
                    class="form-control"
                    v-model="BasicInfomation.creditCode"
                    required
                  />
                </div>
              </div>
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >所属行业：</label
              >
              <div class="col-lg-4 col-md-9 m-b-10">
                <dictSelect
                  :show="false"
                  @select="industrySelect"
                  :value="BasicInfomation.industryId"
                  :pid="'3a021d7b-6c1b-98f5-f8cf-9404f68574a6'"
                ></dictSelect>
              </div>
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >所属网格：</label
              >
              <div class="col-lg-4 col-md-9 m-b-10">
                <v-select
                  :show="false"
                  v-model="BasicInfomation.gridId"
                  @input="gridIdSelect"
                  :reduce="
                    (gridheadEquipmentDict) => gridheadEquipmentDict.value
                  "
                  :options="gridheadEquipmentDict"
                ></v-select>
              </div>
            </div>
            <!-- radio-begin -->
            <div class="form-group row m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >安全性：</label
              >
              <div class="col-lg-4 col-md-9">
                <div class="radio radio-css radio-inline m-r-15">
                  <input
                    type="radio"
                    name="default_radio"
                    id="inlineCssRadio1"
                    v-model="BasicInfomation.safetyLevel"
                    value="0"
                  />
                  <label for="inlineCssRadio1" class="text-red-lighter">
                    <span class="label label-danger">高危</span></label
                  >
                </div>
                <div class="radio radio-css radio-inline">
                  <input
                    type="radio"
                    name="default_radio"
                    v-model="BasicInfomation.safetyLevel"
                    id="inlineCssRadio2"
                    value="1"
                  />
                  <label for="inlineCssRadio2" class="text-yellow-lighter"
                    ><span class="label label-warning">危险</span></label
                  >
                </div>
                <div class="radio radio-css radio-inline">
                  <input
                    type="radio"
                    name="default_radio"
                    v-model="BasicInfomation.safetyLevel"
                    id="inlineCssRadio3"
                    value="2"
                  />
                  <label for="inlineCssRadio3" class="text-green-lighter"
                    ><span class="label label-green">安全</span>
                  </label>
                </div>
              </div>
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >所属区域：</label
              >
              <div class="col-lg-4 col-md-9 m-b-10">
                <dictSelect
                  :show="false"
                  :value="BasicInfomation.regionId"
                  @select="regionIdIdSelect"
                  :pid="'3a019cfc-ef88-6b72-7bc4-f7c04aa3e94c'"
                ></dictSelect>
              </div>
            </div>
            <!-- radio-end -->
            <div class="row form-group">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >企业地址：</label
              >
              <div class="col-lg-10 col-md-9">
                <mapInput
                  v-on:charge="addressCharge"
                  ref="mapInput1"
                  :address="BasicInfomation.address"
                ></mapInput>
              </div>
            </div>
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >法人代表：</label
              >
              <div class="col-lg-4 col-md-9">
                <div class="input-group">
                  <input
                    class="form-control"
                    data-parsley-range="[5,10]"
                    v-model="BasicInfomation.legal"
                    required
                  />
                </div>
              </div>
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >法人身份证：</label
              >
              <div class="col-lg-4 col-md-9">
                <div class="input-group">
                  <input
                    class="form-control"
                    data-parsley-required="true"
                    v-model="BasicInfomation.legalIdCard"
                  />
                </div>
              </div>
            </div>
            <!-- begin  row -->
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >联系电话：</label
              >
              <div class="col-lg-4 col-md-9">
                <div class="input-group">
                  <input
                    class="form-control"
                    v-model="BasicInfomation.legalTel"
                    placeholder="法人代表联系电话"
                    required
                  />
                </div>
              </div>
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >主要产品：</label
              >
              <div class="col-lg-4 col-md-9">
                <div class="input-group">
                  <input
                    class="form-control"
                    v-model="BasicInfomation.mainProduct"
                  />
                </div>
              </div>
            </div>
            <!-- end row -->
            <!-- begin  row -->
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >生产许可编号：</label
              >
              <div class="col-lg-4 col-md-9">
                <div class="input-group">
                  <input
                    class="form-control"
                    data-parsley-range="[5,10]"
                    v-model="BasicInfomation.productionNum"
                  />
                </div>
              </div>
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >从业人数：</label
              >
              <div class="col-lg-4 col-md-9">
                <div class="input-group">
                  <input
                    class="form-control"
                    v-model="BasicInfomation.jobNum"
                    placeholder="0"
                    required
                  />
                </div>
              </div>
            </div>
            <!-- end row -->
            <!-- begin  row -->
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >建筑面积：</label
              >
              <div class="col-lg-4 col-md-9">
                <div class="input-group">
                  <input
                    class="form-control"
                    data-parsley-range="[5,10]"
                    v-model="BasicInfomation.floorage"
                    placeholder="平方米"
                    required
                  />
                </div>
              </div>
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >产值(万)：</label
              >
              <div class="col-lg-4 col-md-9">
                <div class="input-group">
                  <input
                    class="form-control"
                    v-model="BasicInfomation.outputValue"
                    placeholder="请输入产值(万)"
                    required
                  />
                </div>
              </div>
            </div>
            <!-- end row -->
            <!-- radio-begin -->
            <div class="form-group row m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >有危化品：</label
              >
              <div class="col-lg-4 col-md-9">
                <div class="radio radio-css radio-inline m-r-15">
                  <input
                    type="radio"
                    id="inlineCssRadio4"
                    v-model="BasicInfomation.isDangerous"
                    value="true"
                  />
                  <label for="inlineCssRadio4">是</label>
                </div>
                <div class="radio radio-css radio-inline">
                  <input
                    type="radio"
                    v-model="BasicInfomation.isDangerous"
                    id="inlineCssRadio5"
                    value="false"
                    checked
                  />
                  <label for="inlineCssRadio5">否</label>
                </div>
              </div>
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >标准化等级：</label
              >
              <div class="col-lg-4 col-md-9">
                <select
                  class="form-control"
                  v-model="BasicInfomation.mainStandardLevel"
                >
                  <option>未评定</option>
                  <option>一级</option>
                  <option>二级</option>
                  <option>三级</option>
                </select>
              </div>
            </div>
          </div>
          <!-- panel-body end -->
        </div>
        <!-- <hr class="m-b-40" /> -->
        <h5 class="width-150">
          <i class="fas fa-cloud-upload-alt fa-1x"></i>证件上传
        </h5>
        <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
          <div class="panel-body">
            <div class="form-group row">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >营业执照：</label
              >
              <div class="page-content col-lg-4 col-md-9">
                <div>
                  <upload
                    :maxSize="10"
                    :value="BasicInfomation.businessLicense"
                    @finishUpload="finishAll"
                  >
                  </upload>
                </div>
              </div>
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >生产许可证：</label
              >
              <div class="page-content col-lg-4 col-md-9">
                <div>
                  <upload
                    :maxSize="10"
                    :value="BasicInfomation.productionLicense"
                    @finishUpload="finishAll1"
                  >
                  </upload>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <hr class="m-b-40" /> -->
        <!-- radio-end -->
        <h5 class="width-150"><i class="fas fa-user fa-1x"></i> 主要负责人</h5>
        <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
          <div class="panel-body">
            <div class="row form-group">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >姓名：</label
              >
              <div class="col-lg-4 col-md-9">
                <div class="input-group">
                  <input
                    class="form-control"
                    v-model="BasicInfomation.mainUserName"
                    required
                  />
                </div>
              </div>
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >电话号码：</label
              >
              <div class="col-lg-4 col-md-9">
                <div class="input-group">
                  <input
                    v-model="BasicInfomation.mainTel"
                    class="form-control"
                    placeholder="999-9999-9999"
                    required
                  />
                </div>
              </div>
            </div>
            <!-- radio-begin -->
            <div class="form-group row">
              <!-- <label class="col-lg-2 col-md-3 col-form-label text-right"
                >是否持证：</label
              >
              <div class="col-lg-4 col-md-9">
                <div class="radio radio-css radio-inline m-r-15">
                  <input
                    type="radio"
                    id="CertRadio1"
                    v-model="BasicInfomation.mainIsCard"
                    value="true"
                    checked
                  />
                  <label for="CertRadio1">是</label>
                </div>
                <div class="radio radio-css radio-inline">
                  <input
                    type="radio"
                    v-model="BasicInfomation.mainIsCard"
                    id="CertRadio2"
                    value="false"
                  />
                  <label for="CertRadio2">否</label>
                </div>
              </div> -->
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >安全生产诚信等级：</label
              >
              <div class="col-lg-4 col-md-9">
                <select
                  class="form-control"
                  v-model="BasicInfomation.mainSincerityLevel"
                >
                  <option>A</option>
                  <option>B</option>
                  <option>C</option>
                  <option>D</option>
                </select>
              </div>
            </div>
            <!-- radio-end -->
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >证件号：</label
              >
              <div class="col-lg-4 col-md-9">
                <div class="input-group">
                  <input
                    class="form-control"
                    v-model="BasicInfomation.mainIdCard"
                  />
                </div>
              </div>
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >证件有效期：</label
              >
              <div class="col-lg-4 col-md-9">
                <div class="input-group">
                  <date-picker
                    id="dateTimeInput"
                    v-model="BasicInfomation.mainValidity"
                  ></date-picker>
                  <div class="input-group-append">
                    <label for="dateTimeInput" class="input-group-text">
                      <i class="fa fa-calendar"></i>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >标准化等级：</label
              >
              <div class="col-lg-4 col-md-9">
                <select
                  class="form-control"
                  v-model="BasicInfomation.mainStandardLevel"
                >
                  <option>未评定</option>
                  <option>一级</option>
                  <option>二级</option>
                  <option>三级</option>
                </select>
              </div>
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >持证情况：</label
              >
              <div class="col-lg-4 col-md-9">
                <div class="input-group">
                  <select
                    class="form-control"
                    v-model="BasicInfomation.mainSafeLevel"
                  >
                    <option>无</option>
                    <option>安全生产企业主要负责人证书</option>
                    <option>初级注册安全生产工程师</option>
                    <option>中级注册安全生产工程师</option>
                    <option>高级注册安全生产工程师</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h5 class="width-150">制度建设</h5>
        <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
          <div class="panel-body">
            <!-- radio-begin -->
            <div class="form-group row m-b-10">
              <label class="col-lg-3 col-md-4 col-form-label text-right"
                >有安全生产责任书</label
              >
              <div class="col-lg-3 col-md-8">
                <div class="radio radio-css radio-inline m-r-15">
                  <input
                    type="radio"
                    v-model="BasicInfomation.isSafeBook"
                    id="CertRadio3"
                    value="true"
                    checked
                  />
                  <label for="CertRadio3">是</label>
                </div>
                <div class="radio radio-css radio-inline">
                  <input
                    type="radio"
                    id="CertRadio4"
                    value="false"
                    v-model="BasicInfomation.isSafeBook"
                  />
                  <label for="CertRadio4">否</label>
                </div>
              </div>
              <label class="col-lg-3 col-md-4 col-form-label text-right"
                >有教育培训制度</label
              >
              <div class="col-lg-3 col-md-8">
                <div class="radio radio-css radio-inline m-r-15">
                  <input
                    type="radio"
                    id="CertRadio5"
                    v-model="BasicInfomation.isEduTrain"
                    value="true"
                    checked
                  />
                  <label for="CertRadio5">是</label>
                </div>
                <div class="radio radio-css radio-inline">
                  <input
                    type="radio"
                    id="CertRadio6"
                    value="false"
                    v-model="BasicInfomation.isEduTrain"
                  />
                  <label for="CertRadio6">否</label>
                </div>
              </div>
            </div>
            <!-- radio-end -->
            <!-- radio-begin -->
            <div class="form-group row m-b-10">
              <label class="col-lg-3 col-md-4 col-form-label text-right"
                >有安全生产规章制度及操作规范</label
              >
              <div class="col-lg-3 col-md-8">
                <div class="radio radio-css radio-inline m-r-15">
                  <input
                    type="radio"
                    v-model="BasicInfomation.isFGMP"
                    id="CertRadio7"
                    value="true"
                    checked
                  />
                  <label for="CertRadio7">是</label>
                </div>
                <div class="radio radio-css radio-inline">
                  <input
                    type="radio"
                    id="CertRadio8"
                    value="false"
                    v-model="BasicInfomation.isFGMP"
                  />
                  <label for="CertRadio8">否</label>
                </div>
              </div>
              <label class="col-lg-3 col-md-4 col-form-label text-right"
                >有设备检查与检修制度</label
              >
              <div class="col-lg-3 col-md-8">
                <div class="radio radio-css radio-inline m-r-15">
                  <input
                    type="radio"
                    v-model="BasicInfomation.isCheckUp"
                    id="CertRadio9"
                    value="true"
                    checked
                  />
                  <label for="CertRadio9">是</label>
                </div>
                <div class="radio radio-css radio-inline">
                  <input
                    type="radio"
                    v-model="BasicInfomation.isCheckUp"
                    id="CertRadio10"
                    value="false"
                  />
                  <label for="CertRadio10">否</label>
                </div>
              </div>
            </div>
            <!-- radio-end -->
            <!-- radio-begin -->
            <div class="form-group row m-b-10">
              <label class="col-lg-3 col-md-4 col-form-label text-right"
                >有安全隐患排查制度</label
              >
              <div class="col-lg-3 col-md-8">
                <div class="radio radio-css radio-inline m-r-15">
                  <input
                    type="radio"
                    v-model="BasicInfomation.isDangerCheck"
                    id="CertRadio11"
                    value="true"
                    checked
                  />
                  <label for="CertRadio11">是</label>
                </div>
                <div class="radio radio-css radio-inline">
                  <input
                    type="radio"
                    v-model="BasicInfomation.isDangerCheck"
                    id="CertRadio12"
                    value="false"
                  />
                  <label for="CertRadio12">否</label>
                </div>
              </div>
              <label class="col-lg-3 col-md-4 col-form-label text-right"
                >有工伤保险</label
              >
              <div class="col-lg-3 col-md-8">
                <div class="radio radio-css radio-inline m-r-15">
                  <input
                    type="radio"
                    v-model="BasicInfomation.isInjuryInsure"
                    id="CertRadio13"
                    value="true"
                    checked
                  />
                  <label for="CertRadio13">是</label>
                </div>
                <div class="radio radio-css radio-inline">
                  <input
                    type="radio"
                    v-model="BasicInfomation.isInjuryInsure"
                    id="CertRadio14"
                    value="false"
                  />
                  <label for="CertRadio14">否</label>
                </div>
              </div>
            </div>
            <!-- radio-end -->
          </div>
        </div>
        <!-- table begin -->

        <div class="row form-group m-b-10">
          <h5 class="col-lg-11 col-md-9">安全管理人员</h5>
        </div>
        <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
          <div class="panel-body">
            <vxe-toolbar ref="xToolbar" :loading="loading">
              <template #buttons>
                <vxe-button
                  status="primary"
                  content="新增"
                  @click="insertEvent"
                ></vxe-button>
              </template>
            </vxe-toolbar>

            <vxe-table
              border
              resizable
              show-overflow
              keep-source
              ref="xTable"
              height="300"
              :data="tableData"
              :edit-rules="validRules"
              :layouts="[
                'PrevPage',
                'JumpNumber',
                'NextPage',
                'FullJump',
                'Sizes',
                'Total',
              ]"
              :edit-config="{
                trigger: 'click',
                mode: 'row',
                showUpdateStatus: true,
                showInsertStatus: true,
              }"
            >
              <vxe-column type="checkbox" width="60"></vxe-column>
              <vxe-column
                field="name"
                title="姓名"
                :edit-render="{}"
                width="100"
              >
                <template #edit="{ row }">
                  <vxe-input v-model="row.name" type="text"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column
                field="idCard"
                title="身份证"
                :edit-render="{}"
                width="200"
              >
                <template #edit="{ row }">
                  <vxe-input v-model="row.idCard" type="text"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column
                field="isCard"
                title="持证情况"
                :edit-render="{}"
                width="200"
              >
                <template #default="{ row }">
                  <span>{{ formatIsCard(row.isCard) }}</span>
                </template>
                <template #edit="{ row }">
                  <vxe-select
                    v-model="row.isCard"
                    type="text"
                    :options="IsCardList"
                    transfer
                  ></vxe-select>
                </template>
              </vxe-column>
              <vxe-column
                field="tel"
                title="电话"
                :edit-render="{}"
                width="200"
              >
                <template #edit="{ row }">
                  <vxe-input v-model="row.tel"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column
                field="cardValidity"
                title="证书有效期"
                :edit-render="{}"
                width="200"
              >
                <template #edit="{ row }">
                  <vxe-input v-model="row.cardValidity" type="date"></vxe-input>
                </template>
              </vxe-column>

              <vxe-column
                title="证书号"
                width="240"
                field="cardNum"
                :edit-render="{}"
              >
                <template #edit="{ row }">
                  <vxe-input v-model="row.cardNum" type="int"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column
                field="isMain"
                title="是否是负责人"
                :edit-render="{}"
                width="200"
              >
                <template #default="{ row }">
                  <span>{{ formatIsMain(row.isMain) }}</span>
                </template>
                <template #edit="{ row }">
                  <vxe-select
                    v-model="row.isMain"
                    type="text"
                    :options="IsMainList"
                    transfer
                  ></vxe-select>
                </template>
              </vxe-column>
              <vxe-column title="企业id" field="businessId" :visible="false">
                <template #edit="{ row }">
                  <vxe-input v-model="row.businessId"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column title="安全检查员ID" field="id" :visible="false">
                <template #edit="{ row }">
                  <vxe-input v-model="row.id"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column title="操作" width="190">
                <template #default="{ row }">
                  <vxe-button
                    status="warning"
                    content="删除"
                    @click="removeRowEvent(row)"
                  ></vxe-button>
                  <vxe-button
                    status="success"
                    content="保存"
                    @click="saveEvent(row)"
                  ></vxe-button>
                </template>
              </vxe-column>
            </vxe-table>
            <vxe-pager
              border
              icon-prev-page="fa fa-angle-left"
              icon-jump-prev="fa fa-angle-double-left"
              icon-jump-next="fa fa-angle-double-right"
              icon-next-page="fa fa-angle-right"
              icon-jump-more="fa fa-ellipsis-h"
              :loading="loading"
              :current-page="tablePage.currentPage"
              :page-size="tablePage.pageSize"
              :total="tablePage.totalResult"
              :layouts="[
                'PrevPage',
                'JumpNumber',
                'NextPage',
                'FullJump',
                'Sizes',
                'Total',
              ]"
              @page-change="handlePageChange"
            >
            </vxe-pager>
          </div>
        </div>
        <!-- table end -->
        <!-- table begin -->
        <div class="row form-group m-b-10">
          <h5 class="col-lg-11 col-md-9">特种作业人员</h5>
        </div>
        <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
          <div class="panel-body">
            <vxe-toolbar ref="tToolbar" :loading="loading">
              <template #buttons>
                <vxe-button
                  status="primary"
                  content="新增"
                  @click="insertTEvent"
                ></vxe-button>
              </template>
            </vxe-toolbar>

            <vxe-table
              border
              resizable
              show-overflow
              keep-source
              ref="SpecialoperatorTable"
              height="300"
              :data="TtableData"
              :edit-rules="validRules"
              :layouts="[
                'PrevPage',
                'JumpNumber',
                'NextPage',
                'FullJump',
                'Sizes',
                'Total',
              ]"
              :edit-config="{
                trigger: 'click',
                mode: 'row',
                showUpdateStatus: true,
                showInsertStatus: true,
              }"
            >
              <vxe-column type="checkbox" width="60"></vxe-column>
              <vxe-column
                field="name"
                title="姓名"
                :edit-render="{}"
                width="200"
              >
                <template #edit="{ row }">
                  <vxe-input v-model="row.name" type="text"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column
                field="isCard"
                title="持证情况"
                :edit-render="{}"
                width="100"
              >
                <template #default="{ row }">
                  <span>{{ formatIsCard(row.isCard) }}</span>
                </template>
                <template #edit="{ row }">
                  <vxe-select
                    v-model="row.isCard"
                    type="text"
                    :options="IsCardList"
                    transfer
                  ></vxe-select>
                </template>
              </vxe-column>
              <vxe-column
                field="tel"
                title="电话"
                :edit-render="{}"
                width="200"
              >
                <template #edit="{ row }">
                  <vxe-input v-model="row.tel"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column
                field="cardValidity"
                title="证书有效期"
                :edit-render="{}"
                width="200"
              >
                <template #edit="{ row }">
                  <vxe-input v-model="row.cardValidity" type="date"></vxe-input>
                </template>
              </vxe-column>

              <vxe-column
                title="证书号"
                width="240"
                field="cardNum"
                :edit-render="{}"
              >
                <template #edit="{ row }">
                  <vxe-input type="text" v-model="row.cardNum"></vxe-input>
                </template>
              </vxe-column>

              <vxe-column
                title="企业id"
                field="businessId"
                :visible="false"
                width="200"
              >
                <template #edit="{ row }">
                  <vxe-input v-model="row.businessId"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column title="特种作业人员ID" field="id" :visible="false">
                <template #edit="{ row }">
                  <vxe-input v-model="row.id"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column title="操作" width="190">
                <template #default="{ row }">
                  <vxe-button
                    status="warning"
                    content="删除"
                    @click="removeTRowEvent(row)"
                  ></vxe-button>
                  <vxe-button
                    status="success"
                    content="保存"
                    @click="saveTEvent(row)"
                  ></vxe-button>
                </template>
              </vxe-column>
            </vxe-table>
            <vxe-pager
              border
              icon-prev-page="fa fa-angle-left"
              icon-jump-prev="fa fa-angle-double-left"
              icon-jump-next="fa fa-angle-double-right"
              icon-next-page="fa fa-angle-right"
              icon-jump-more="fa fa-ellipsis-h"
              :loading="loading"
              :current-page="TtablePage.currentPage"
              :page-size="TtablePage.pageSize"
              :total="TtablePage.totalResult"
              :layouts="[
                'PrevPage',
                'JumpNumber',
                'NextPage',
                'FullJump',
                'Sizes',
                'Total',
              ]"
              @page-change="handleTPageChange"
            >
            </vxe-pager>
          </div>
        </div>
        <!-- table end -->
        <!-- <hr class="m-b-40" /> -->
        <h5 class="width-150">
          消防器材登记 <i class="fas fa-fire-extinguisher fa-1x"></i>
        </h5>
        <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
          <div class="panel-body">
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-4 col-form-label text-right"
                >灭火器数量：</label
              >
              <input
                class="form-control col-md-8 col-lg-2"
                data-parsley-range="[5,10]"
                v-model="BasicInfomation.extinguisher"
                required
              />
              <label class="col-lg-2 col-md-4 col-form-label text-right"
                >消防栓数量：</label
              >
              <input
                class="col-lg-2 col-md-8 form-control"
                v-model="BasicInfomation.hydrant"
                required
              />
              <label class="col-md-4 col-lg-2 col-form-label text-right"
                >消防泵数量：</label
              >
              <input
                class="col-lg-2 col-md-8 form-control"
                v-model="BasicInfomation.firePumps"
                required
              />
            </div>
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-4 col-form-label text-right">
                沙驰数量：</label
              >
              <input
                class="col-lg-2 col-md-8 form-control"
                data-parsley-range="[5,10]"
                v-model="BasicInfomation.basin"
                required
              />
              <label class="col-lg-2 col-md-4 col-form-label text-right">
                其他数量：</label
              >
              <input
                class="col-lg-2 col-md-8 form-control"
                v-model="BasicInfomation.other"
                required
              />
            </div>
          </div>
        </div>

        <!-- <hr class="m-b-40" /> -->
        <h5 class="width-150">
          特种设备登记 <i class="ion-md-construct 1x"></i>
        </h5>
        <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
          <div class="panel-body">
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-4 col-form-label text-right"
                >锅炉数量(个)：</label
              >
              <input
                class="form-control col-md-8 col-lg-2"
                data-parsley-range="[5,10]"
                v-model="BasicInfomation.boiler"
                required
              />
              <label class="col-lg-2 col-md-4 col-form-label text-right"
                >起重机数量(辆)：</label
              >
              <input
                class="col-lg-2 col-md-8 form-control"
                v-model="BasicInfomation.crane"
                required
              />
              <label class="col-md-4 col-lg-2 col-form-label text-right"
                >电梯数量(台)：</label
              >
              <input
                class="col-lg-2 col-md-8 form-control"
                v-model="BasicInfomation.elevator"
                required
              />
            </div>
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-4 col-form-label text-right">
                专用机动车数量(辆)：</label
              >
              <input
                class="col-lg-2 col-md-8 form-control"
                data-parsley-range="[5,10]"
                v-model="BasicInfomation.car"
                required
              />
              <label class="col-lg-2 col-md-4 col-form-label text-right">
                特种其他数量：</label
              >
              <input
                class="col-lg-2 col-md-8 form-control"
                v-model="BasicInfomation.specialOther"
                required
              />
            </div>
          </div>
          <!-- table begin -->
          <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
            <div class="panel-body">
              <vxe-toolbar ref="tToolbar" :loading="loading">
                <template #buttons>
                  <vxe-button
                    status="primary"
                    content="新增"
                    @click="insertREvent"
                  ></vxe-button>
                </template>
              </vxe-toolbar>

              <vxe-table
                border
                resizable
                show-overflow
                keep-source
                ref="PressurevesselTable"
                height="300"
                :data="RtableData"
                :edit-rules="validRules"
                :layouts="[
                  'PrevPage',
                  'JumpNumber',
                  'NextPage',
                  'FullJump',
                  'Sizes',
                  'Total',
                ]"
                :edit-config="{
                  trigger: 'click',
                  mode: 'row',
                  showUpdateStatus: true,
                  showInsertStatus: true,
                }"
              >
                <vxe-column type="checkbox" width="60"></vxe-column>
                <vxe-column
                  field="dictId"
                  title="容压器"
                  :edit-render="{}"
                  width="300"
                >
                  <!-- 请求数据字典，但是页面显示ID,显示不了label -->
                  <template #default="{ row }">
                    <span>{{ formatdictId(row.dictId) }}</span>
                  </template>
                  <template #edit="{ row }">
                    <vxe-select
                      v-model="row.dictId"
                      type="text"
                      :options="SpecialEquipmentDict"
                      transfer
                    ></vxe-select>
                  </template>
                  <!-- 通过formatdictId显示 -->
                  <!-- <template #default="{ row }">
                    <span>{{ formatdictId(row.dictId) }}</span>
                  </template>
                  <template #edit="{ row }">
                    <vxe-select
                      v-model="row.dictId"
                      type="text"
                      :options="dictId"
                      transfer
                    ></vxe-select>
                  </template> -->
                </vxe-column>
                <vxe-column
                  field="num"
                  title="数量"
                  :edit-render="{}"
                  width="300"
                >
                  <template #edit="{ row }">
                    <vxe-input v-model="row.num" type="int"></vxe-input>
                  </template>
                </vxe-column>
                <vxe-column
                  field="unit"
                  title="单位"
                  :edit-render="{}"
                  width="300"
                >
                  <template #edit="{ row }">
                    <vxe-input
                      v-model="row.unit"
                      type="text"
                      :digits="2"
                    ></vxe-input>
                  </template>
                </vxe-column>
                <vxe-column title="企业id" field="businessId" :visible="false">
                  <template #edit="{ row }">
                    <vxe-input v-model="row.businessId"></vxe-input>
                  </template>
                </vxe-column>

                <vxe-column title="操作" width="300">
                  <template #default="{ row }">
                    <vxe-button
                      status="warning"
                      content="删除"
                      @click="removeRRowEvent(row)"
                    ></vxe-button>
                    <vxe-button
                      status="success"
                      content="保存"
                      @click="saveREvent(row)"
                    ></vxe-button>
                  </template>
                </vxe-column>
              </vxe-table>
              <vxe-pager
                border
                icon-prev-page="fa fa-angle-left"
                icon-jump-prev="fa fa-angle-double-left"
                icon-jump-next="fa fa-angle-double-right"
                icon-next-page="fa fa-angle-right"
                icon-jump-more="fa fa-ellipsis-h"
                :loading="loading"
                :current-page="RtablePage.currentPage"
                :page-size="RtablePage.pageSize"
                :total="RtablePage.totalResult"
                :layouts="[
                  'PrevPage',
                  'JumpNumber',
                  'NextPage',
                  'FullJump',
                  'Sizes',
                  'Total',
                ]"
                @page-change="handleTPageChange"
              >
              </vxe-pager>
            </div>
          </div>
          <!-- table end -->
        </div>
      </form>
    </div>

    <b-modal id="modalAdds" no-fade cancel-variant="default" title="请选择">
      <p>Modal body content here...</p>
    </b-modal>
    <!-- end register-content -->
  </div>
</template>
<script>
// import PageOptions from '../config/PageOptions.vue'
import { userApi } from "@/api/system/userApi";
import { businessApi } from "@/api/system/businessApi";
import dictSelect from "@/components/dictSelect/index.vue";
import mapInput from "@/components/map-input/index.vue";
import { dictApi } from "@/api/system/dictApi";

const MODAL_WIDTH = 1024;
export default {
  components: {
    dictSelect,
    mapInput,
  },
  data() {
    return {
      tablePage: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
      TtablePage: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
      RtablePage: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
      loading: false,
      validRules: {
        Name: [{ required: true, message: "名称必须填写" }],
      },
      TtableData: [],
      RtableData: [],
      tableData: [],
      //特种设备数据字典集
      SpecialEquipmentDict: [],
      gridheadEquipmentDict: [],
      BasicInfomation: {
        name: "",
        address: "",
        regionId: "",
        gridId: "",
        industryId: "",
        safetyLevel: "2",
        businessLicense: "",
        creditCode: "",
        legal: "",
        legalIdCard: "",
        legalTel: "",
        mainProduct: "",
        productionLicense: "",
        productionNum: "",
        jobNum: "",
        floorage: "",
        outputValue: "",
        isDangerous: "true",
        mainUserName: "",
        mainTel: "",
        mainIdCard: "",
        // mainIsCard: "true",
        mainValidity: "",
        mainSincerityLevel: "",
        mainStandardLevel: "",
        mainSafeLevel: "",
        isSafeBook: "true",
        isEduTrain: "true",
        isFGMP: "true",
        isCheckUp: "true",
        isDangerCheck: "true",
        isInjuryInsure: "true",
        extinguisher: "",
        hydrant: "",
        firePumps: "",
        other: "",
        basin: "",
        boiler: "",
        crane: "",
        elevator: "",
        car: "",
        specialOther: "",
        longitude: "0",
        latitude: "0",
        id: "",
      },
      d: {
        file1: [],
        file2: [],
      },
      IsMainList: [
        { label: "是", value: "true" },
        { label: "否", value: "false" },
      ],
      IsCardList: [
        { label: "是", value: "true" },
        { label: "否", value: "false" },
      ],
      dictId: [
        { label: "干冰", value: "3a01a6a5-3c99-0bd8-849e-6cb54102ccce" },
        { label: "氮气", value: "3a01a6a5-5b34-1620-8427-8eac0d712c71" },
        { label: "氢气", value: "3a01a6a5-7a6c-a19a-5514-cc8f6234d064" },
      ],
      id: [],
      modalWidth: MODAL_WIDTH,
    };
  },
  mounted() {
    let id = this.$route.query.id;
    this.id = id;
    if (id) {
      this.GetData();
      this.GetOfficerList();
      this.GetSpecialoperatorList();
      this.GetPressurevesselList();
    }
    this.readSpecialEquipmentDict();
    this.GetgridheadList();
  },
  created() {
    this.modalWidth =
      window.innerWidth < MODAL_WIDTH ? window.innerWidth * 0.8 : MODAL_WIDTH;

    var dict = sessionStorage.getItem("SpecialEquipment");
    console.log(dict);
  },
  methods: {
    ToastSuccess(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "Success") {
        title = "保存成功";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "success",
        title: title,
      });
    },
    ToastWarning(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "False") {
        title = "保存出错";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "warning",
        title: title,
      });
    },
    //请求Api获取企业信息
    GetData() {
      let that = this;
      businessApi
        .GetData({
          //入参为地址栏传入的ID值
          id: that.$route.query.id,
        })
        .then((res) => that.GetSuccess(that, res))
        .finally(() => {});
    },
    //获取安全管理员列表
    GetOfficerList() {
      let that = this;
      businessApi
        .GetOfficerList({
          //入参为地址栏传入的ID值
          SkipCount: (that.tablePage.currentPage - 1) * that.tablePage.pageSize,
          MaxResultCount: that.tablePage.pageSize,
          Sorting: "",
          BusinessId: that.id,
        })
        .then((res) => that.GetOfficerListSuccess(that, res))
        .finally(() => {});
    },
    //获取特种作业人员列表
    GetSpecialoperatorList() {
      let that = this;
      businessApi
        .GetSpecialoperatorList({
          SkipCount: (that.tablePage.currentPage - 1) * that.tablePage.pageSize,
          MaxResultCount: that.tablePage.pageSize,
          Sorting: "",
          BusinessId: that.id,
        })
        .then((res) => that.GetSpecialoperatorListSuccess(that, res))
        .finally(() => {});
    },
    // 获取容压器列表
    GetPressurevesselList() {
      let that = this;
      businessApi
        .GetPressurevesselList({
          SkipCount: (that.tablePage.currentPage - 1) * that.tablePage.pageSize,
          MaxResultCount: that.tablePage.pageSize,
          Sorting: "",
          BusinessId: that.id,
        })
        .then((res) => that.GetPressurevesselListSuccess(that, res))
        .finally(() => {});
    },
    GetPressurevesselListSuccess(that, res) {
      if (res.success) {
        that.RtableData = res.data.items;
        that.RtablePage.RtotalResult = res.data.totalCount;
      } else {
        console.log(res);
      }
    },
    GetSpecialoperatorListSuccess(that, res) {
      if (res.success) {
        that.TtableData = res.data.items;
        that.TtablePage.TtotalResult = res.data.totalCount;
      } else {
        console.log(res);
      }
    },
    GetOfficerListSuccess(that, res) {
      if (res.success) {
        that.tableData = res.data.items;
        that.tablePage.totalResult = res.data.totalCount;
      } else {
        console.log(res);
      }
    },
    GetSuccess(that, res) {
      if (res.success) {
        // console.log(res.data);
        this.BasicInfomation = res.data;
        //异步获取地址，并向组件传值
        this.$refs.mapInput1.initAdds({
          address: this.BasicInfomation.address,
          coordinate: [
            this.BasicInfomation.longitude,
            this.BasicInfomation.latitude,
          ],
        });
      } else {
        console.log(res);
      }
    },
    checkForm: function (e) {
      e.preventDefault();
      console.log(this.address);
      //提交的数据
      const params = {
        id: this.id,
        name: this.BasicInfomation.name, //公司名称
        address: this.BasicInfomation.address, //公司地址
        regionId: this.BasicInfomation.regionId, //所属区域Id
        safetyLevel: this.BasicInfomation.safetyLevel, //安全性: 0:高危 1:危险 2:安全
        gridId: this.BasicInfomation.gridId, //所属网格Id
        industryId: this.BasicInfomation.industryId, //所属行业Id
        businessLicense: this.BasicInfomation.businessLicense, //营业执照
        creditCode: this.BasicInfomation.creditCode, //统一社会信用代码
        legal: this.BasicInfomation.legal, //法人代表
        legalIdCard: this.BasicInfomation.legalIdCard, //法人身份证号
        legalTel: this.BasicInfomation.legalTel, //法人联系电话
        mainProduct: this.BasicInfomation.mainProduct, //主要产品
        productionLicense: this.BasicInfomation.productionLicense, //生产许可证
        productionNum: this.BasicInfomation.productionNum, //生产许可编号
        jobNum: this.BasicInfomation.jobNum, //从业人数
        floorage: this.BasicInfomation.floorage, //建筑面积
        outputValue: this.BasicInfomation.outputValue, //产值
        isDangerous: this.BasicInfomation.isDangerous, //是否有危化品
        mainUserName: this.BasicInfomation.mainUserName, //主要负责人姓名
        mainTel: this.BasicInfomation.mainTel, //手机号码
        mainIdCard: this.BasicInfomation.mainIdCard, //证件号
        // mainIsCard: this.BasicInfomation.mainIsCard, //是否持证
        mainValidity: this.BasicInfomation.mainValidity, //有效期
        mainSincerityLevel: this.BasicInfomation.mainSincerityLevel, //安全生产诚信等级
        mainStandardLevel: this.BasicInfomation.mainStandardLevel, //标准等级
        mainSafeLevel: this.BasicInfomation.mainSafeLevel, //安全等级
        isSafeBook: this.BasicInfomation.isSafeBook, //有安全生产责任书
        isEduTrain: this.BasicInfomation.isEduTrain, //有教育与培训制度
        isFGMP: this.BasicInfomation.isFGMP, //有安全生产规章制度及操作规范
        isCheckUp: this.BasicInfomation.isCheckUp, //有设备检查与检修制度
        isDangerCheck: this.BasicInfomation.isDangerCheck, //有安全隐患排查制度
        isInjuryInsure: this.BasicInfomation.isInjuryInsure, //有工伤保险
        extinguisher: this.BasicInfomation.extinguisher, //灭火器数量
        hydrant: this.BasicInfomation.hydrant, //消防栓数量
        firePumps: this.BasicInfomation.firePumps, //消防泵数量
        other: this.BasicInfomation.other, //沙池数量
        basin: this.BasicInfomation.basin, //其他数量
        boiler: this.BasicInfomation.boiler, //锅炉数量
        crane: this.BasicInfomation.crane, //起重机数量
        elevator: this.BasicInfomation.elevator, //电梯数量
        car: this.BasicInfomation.car, //专用机动车数量
        specialOther: this.BasicInfomation.specialOther, //特种其他数量
        longitude: this.BasicInfomation.longitude, //经度
        latitude: this.BasicInfomation.latitude, //维度
      };
      if (this.BasicInfomation.regionId == null) {
        this.$swal({
          title: "",
          text: "请选择所属区域", //提示语
          type: "question", //类型
          showCancelButton: false, //  显示取消按钮
          buttonsStyling: false,
          // confirmButtonText: "确定",
          // cancelButtonText: "取消",
          confirmButtonClass: "btn m-r-5 btn-" + "error" + "",
          cancelButtonClass: "btn btn-default",
        });
      } else if (this.BasicInfomation.industryId == null) {
        this.$swal({
          title: "",
          text: "请选择所属行业", //提示语
          type: "question", //类型
          showCancelButton: false, //  显示取消按钮
          buttonsStyling: false,
          confirmButtonClass: "btn m-r-5 btn-" + "error" + "",
          cancelButtonClass: "btn btn-default",
        });
      } else if (this.BasicInfomation.gridId == null) {
        this.$swal({
          title: "",
          text: "请选择所属网格", //提示语
          type: "提示", //类型
          showCancelButton: false, //  显示取消按钮
          buttonsStyling: false,
          confirmButtonClass: "btn m-r-5 btn-" + "确定" + "",
          cancelButtonClass: "btn btn-default",
        });
      } else {
        //e.preventDefault();
        let that = this;
        // 通过地址栏ID判断新增还是修改
        if (that.id) {
          // that.params.id=that.id
          businessApi
            .UpdataCompany(params)
            .then((res) => that.UpdataSuccess(that, res))
            .finally(() => {});
        } else {
          //新建公司信息
          userApi
            .CreateCompany(params)
            .then((res) => that.createSuccess(that, res))
            .finally(() => {});
        }
      }
    },
    UpdataSuccess(that, res) {
      if (res.success) {
        that.ToastSuccess(res);
        this.$router.push({ path: "/CompanyList?id=" });
      } else {
        that.ToastWarning(res);
      }
    },
    createSuccess(that, res) {
      if (res.success) {
        // 创建成功后提示
        that.ToastSuccess(res);
      } else {
        // 创建失败提示
        that.ToastWarning(res);
      }
    },
    checktable: function () {},
    //文件上传成功后返回的文件组
    finishAll(e) {
      this.BasicInfomation.businessLicense = e[0].res.data.fileInfo.id;
      console.log(e[0].res.data.fileInfo.id);
    },
    finishAll1(e) {
      console.log(e);
      this.BasicInfomation.productionLicense = e[0].res.data.fileInfo.id;
      console.log(e[0].res.data.fileInfo.id);
    },
    formatIsMain(value) {
      if (value === true) {
        return "是";
      }
      if (value === false) {
        return "否";
      }
      return "";
    },
    formatIsCard(value) {
      if (value === true) {
        return "是";
      }
      if (value === false) {
        return "否";
      }
      return "";
    },
    formatdictId(value) {
      if (value === "3a01a6a5-3c99-0bd8-849e-6cb54102ccce") {
        return this.SpecialEquipmentDict[0].label;
      }
      if (value === "3a01a6a5-5b34-1620-8427-8eac0d712c71") {
        return this.SpecialEquipmentDict[1].label;
      }
      if (value === "3a01a6a5-7a6c-a19a-5514-cc8f6234d064") {
        return this.SpecialEquipmentDict[2].label;
      }
      return "";
    },
    saveEvent(row) {
      const tableData = {
        businessId: this.id,
        name: row.name,
        idCard: row.idCard,
        tel: row.tel,
        isCard: row.isCard,
        cardNum: row.cardNum,
        cardValidity: row.cardValidity,
        isMain: row.isMain,
      };
      const $table = this.$refs.xTable;
      // var t=$table;
      console.log(row);
      let res = $table.getRecordset();
      let that = this;
      if (row.id) {
        businessApi
          .UpdataOfficer(row)
          .then((res) => that.UpdataOfficerSuccess(that, res))
          .finally(() => {});
      } else {
        businessApi
          .Createsecurityofficer(tableData)
          .then((res) => that.CreateofficerSuccess(that, res))
          .finally(() => {});
        console.log(that);
      }
      if (
        res.insertRecords.length <= 0 &&
        res.removeRecords.length <= 0 &&
        res.updateRecords.length <= 0
      ) {
        that.$XModal.message({ content: "数据未改动！", status: "warning" });
        return;
      }
      const errMap = $table.validate().catch((errMap) => errMap);
      if (errMap) {
        return;
      }
    },
    saveTEvent(row) {
      const TtableData = {
        businessId: this.id,
        name: row.name,
        idCard: row.idCard,
        tel: row.tel,
        isCard: row.isCard,
        cardNum: row.cardNum,
        cardValidity: row.cardValidity,
        isMain: row.isMain,
      };
      let that = this;
      //特种作业人员
      if (row.id) {
        businessApi
          .UpdateSpecialoperatorList(row)
          .then((res) => that.UpdateSpecialoperatorListSuccess(that, res))
          .finally(() => {});
      } else {
        businessApi
          .CreateSpecialoperatorList(TtableData)
          .then((res) => that.CreateSpecialoperatorListSuccess(that, res))
          .finally(() => {});
        console.log(that);
      }
    },
    saveREvent(row) {
      const RtableData = {
        businessId: this.id,
        dictId: row.dictId,
        num: row.num,
        unit: row.unit,
      };
      let that = this;
      //容压器
      if (row.id) {
        businessApi
          .UpdatePressurevessel(row)
          .then((res) => that.UpdatePressurevesselSuccess(that, res))
          .finally(() => {});
      } else {
        businessApi
          .CreatePressurevessel(RtableData)
          .then((res) => that.CreatePressurevesselSuccess(that, res))
          .finally(() => {});
        console.log(that);
      }
    },
    // 表格删除
    removeTRowEvent(row) {
      let that = this;
      if (row.id) {
        businessApi
          .DeleteSpecialoperatorList({ id: row.id })
          .then((res) => that.DeleteSpecialoperatorListSuccess(that, res, row))
          .finally(() => {});
      }
    },
    removeRRowEvent(row) {
      let that = this;
      if (row.id) {
        businessApi
          .DeletePressurevessel({ id: row.id })
          .then((res) => that.DeletePressurevesselSuccess(that, res, row))
          .finally(() => {});
      }
    },
    removeRowEvent(row) {
      let that = this;
      if (row.id) {
        businessApi
          .DeleteOfficer({ id: row.id })
          .then((res) => that.DeleteOfficerSuccess(that, res, row))
          .finally(() => {});
      }
    },

    CreateofficerSuccess(that, res) {
      if (res.success) {
        that.ToastSuccess(res);
      } else {
        that.ToastWarning(res);
      }
    },
    UpdateSpecialoperatorListSuccess(that, res) {
      if (res.success) {
        that.ToastSuccess(res);
      } else {
        that.ToastWarning(res);
      }
    },
    CreateSpecialoperatorListSuccess(that, res) {
      if (res.success) {
        that.ToastSuccess(res);
        console.log(res);
      } else {
        that.ToastWarning(res);
        console.log(res);
      }
    },

    UpdataOfficerSuccess(that, res) {
      if (res.success) {
        that.ToastSuccess(res);
      } else {
        that.ToastWarning(res);
      }
    },
    DeleteSpecialoperatorListSuccess(that, res, row) {
      if (res.success) {
        const $table = this.$refs.SpecialoperatorTable;
        $table.remove(row);
        console.log(res);
      } else {
        console.log(res);
      }
    },
    DeleteOfficerSuccess(that, res, row) {
      if (res.success) {
        const $table = this.$refs.xTable;
        $table.remove(row);
        console.log(res);
      } else {
        console.log(res);
      }
    },
    DeletePressurevesselSuccess(that, res, row) {
      if (res.success) {
        const $table = this.$refs.PressurevesselTable;
        $table.remove(row);
        console.log(res);
      } else {
        console.log(res);
      }
    },
    CreatePressurevesselSuccess(that, res) {
      if (res.success) {
        that.ToastSuccess(res);
        console.log(res);
      } else {
        that.ToastWarning(res);
        console.log(res);
      }
    },
    UpdatePressurevesselSuccess(that, res) {
      if (res.success) {
        that.ToastSuccess(res);
        console.log(res);
      } else {
        that.ToastWarning(res);
        console.log(res);
      }
    },
    //安全员列表新增
    async insertEvent() {
      if (this.id) {
        const $table = this.$refs.xTable;
        const newRecord = {
          BusinessId: this.id,
          Name: "",
          IdCard: "",
          Tel: "",
          IsCard: "",
          CardNum: "",
          CardValidity: "",
          IsMain: "",
        };
        const { row: newRow } = await $table.insert(newRecord);
        await $table.setActiveRow(newRow);
      } else {
        alert("请保存企业后新增！");
      }
    },
    //特种作业人员列表新增
    async insertTEvent() {
      if (this.id) {
        const $table = this.$refs.SpecialoperatorTable;
        const newRecord = {
          BusinessId: this.id,
          Name: "",
          IdCard: "",
          IsCard: "",
          CardNum: "",
          CardValidity: "",
        };
        const { row: newRow } = await $table.insert(newRecord);
        await $table.setActiveRow(newRow);
      } else {
        alert("请保存企业后新增！");
      }
    },
    //容压器列表新增
    async insertREvent() {
      if (this.id) {
        const $table = this.$refs.PressurevesselTable;
        const newRecord = {
          BusinessId: this.id,
          DictId: "",
          Num: "",
          Unit: "",
        };
        const { row: newRow } = await $table.insert(newRecord);
        await $table.setActiveRow(newRow);
      } else {
        alert("请保存企业后新增！");
      }
    },
    async removeSelectEvent() {
      const $table = this.$refs.xTable;
      await $table.removeCheckboxRow();
    },

    handlePageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      //改变分页参数，重新请求Api
      this.GetOfficerList();
    },
    handleTPageChange({ currentPage, pageSize }) {
      this.TtablePage.currentPage = currentPage;
      this.TtablePage.pageSize = pageSize;
      //改变分页参数，重新请求Api
      this.GetSpecialoperatorList();
      // this.findList();
    },
    //接收MapInput组件传回的值
    addressCharge() {
      if (arguments) {
        var n = arguments[1].split(",");
        this.BasicInfomation.longitude = n[0];
        this.BasicInfomation.latitude = n[1];
        this.BasicInfomation.address = arguments[0];
        console.log(n[1]);
        console.log("addressCharge", arguments);
      }
    },
    //行业选择框返回值
    industrySelect(id) {
      this.BasicInfomation.industryId = id;
      console.log("industrySelect", id);
    },
    //所属区域选择框返回值
    regionIdIdSelect(id) {
      this.BasicInfomation.regionId = id;
      console.log("regionIdIdSelect", id);
    },
    //所属网格选择框返回值
    gridIdSelect(id) {
      console.log("gridIdSelect", id);
      this.BasicInfomation.gridId = id;
    },
    // //容压器选择返回值
    // dictIdSelect(id) {
    //   this.BasicInfomation.regionId = id;
    //   console.log("dictIdSelect", id);
    // },
    //读取[特种设备]数据字典
    readSpecialEquipmentDict() {
      let that = this;
      let options = [];
      that.SpecialEquipmentDict = [];
      dictApi
        .getDictUrl({
          id: "3a01a6a4-e976-402d-cd14-d2c50688e89b",
          bChild: false,
        })
        .then((res) => {
          // console.log('readSpecialEquipmentDict',res.data)
          res.data.forEach((element) => {
            options.push({
              label: element.name,
              value: element.id,
            });
          });
          that.SpecialEquipmentDict = options;
        });
    },
    //读取网格长数据字典
    GetgridheadList() {
      let that = this;
      let options = [];
      that.gridheadEquipmentDict = [];
      businessApi
        .GetUsersList({
          SkipCount: 1,
          MaxResultCount: 100,
          Sorting: "",
          Filter: "",
          RoleId: "2",
        })
        .then((res) => {
          res.data.items.forEach((element) => {
            options.push({
              value: element.id,
              label: element.name,
            });
          });
          that.gridheadEquipmentDict = options;
        });
    },
  },
};
</script>
<style>
.footer {
  position: fixed;
  top: 100px;
  right: -5%;
  width: 80px;
  line-height: var(--footer-height);
  background: #3f9bf7;
  color: #fff;
}
</style>
